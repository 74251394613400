
.profile {
  display: table;

  .profile-sidebar {
    border-right: 1px solid #CCC;
    padding-right: 0px;
    width: 200px;
    float: left;
    padding-left: 10px;
  }

  .profile-content {
    border-left: 1px solid #CCC;
    padding-left: 19px;
    margin-left: -1px;
    width: 800px;
    float: left;

    .row {
      margin-left: -10px;
    }
  }

  .media {
    padding: 0px;
    width: 150px;
    height: 150px;
    margin: 5px 0;
  }
}

.qualification {
  display: flex;
  margin-bottom: 15px;

  .comment {
    font-size: 13px;
    padding: 5px 10px;
    background-color: #eeeeee;
    background-image: -moz-linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(255, 255, 255) 100%);
    background-image: -webkit-linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(255, 255, 255) 100%);
    background-image: -ms-linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(255, 255, 255) 100%);
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 62px;

  }

  .arrow {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #eeeeee;

    margin-right: -4px;
    margin-top: 4px;
  }

  img {
    margin-top: 2px;
    margin-right: 5px;
  }

}

.columns-list-2 {
  -webkit-column-count: 2; /* Chrome, Safari, Opera */
  -moz-column-count: 2; /* Firefox */
  column-count: 2;
}

.two-rows {
  display: inline-block;
  margin-right: 10px;

  h3, h4 {
    margin: 0;
  }

  label {
    font-weight: inherit;
  }

  img {
    vertical-align: bottom;
  }

}

.profile-resume {
  padding: 12px;

  a {
    padding-top: 10px;
    margin-right: 10px;
  }

}