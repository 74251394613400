@color-very-bad: rgb(241, 69, 36);
@color-bad: rgb(249, 177, 11);;
@color-regular: rgb(251, 198, 25);
@color-good: rgb(57, 219, 118);
@color-very-good: rgb(19, 195, 85);

.reputation {
  text-align: center;
}

.reputation-very-bad {
  width: 143px !important;
  height: 38px;
  margin-right: 15px;
  border-style: solid;
  border-width: 3px;
  border-color: @color-very-bad;
  background-color: rgb(255,255,255);
  border-radius: 20px;
  text-transform: uppercase;
  color: @color-very-bad;
  padding-left: 20px;
  padding-right: 20px;
  outline:none;

  &:hover, &.selected {
    color: rgb(255,255,255);
    background-color: @color-very-bad;
  }

}

.reputation-bad {
  width: 143px !important;;
  height: 38px;
  margin-right: 15px;
  border-style: solid;
  border-width: 3px;
  border-color: @color-bad;
  background-color: rgb(255,255,255);
  border-radius: 20px;
  text-transform: uppercase;
  color: @color-bad;
  padding-left: 20px;
  padding-right: 20px;
  outline:none;

  &:hover, &.selected {
    color: rgb(255,255,255);
    background-color: @color-bad;
  }
}

.reputation-regular {
  width: 143px !important;;
  height: 38px;
  margin-right: 15px;
  border-style: solid;
  border-width: 3px;
  border-color: @color-regular;
  background-color: rgb(255,255,255);
  border-radius: 20px;
  text-transform: uppercase;
  color: @color-regular;
  padding-left: 20px;
  padding-right: 20px;
  outline:none;

  &:hover, &.selected {
    color: rgb(255,255,255);
    background-color: @color-regular;
  }
}

.reputation-good {
  width: 143px !important;;
  height: 38px;
  margin-right: 15px;
  border-style: solid;
  border-width: 3px;
  border-color: @color-good;
  background-color: rgb(255,255,255);
  border-radius: 20px;
  text-transform: uppercase;
  color: @color-good;
  padding-left: 20px;
  padding-right: 20px;
  outline:none;

  &:hover, &.selected {
    color: rgb(255,255,255);
    background-color: @color-good;
  }
}

.reputation-very-good {
  width: 143px !important;;
  height: 38px;
  margin-right: 15px;
  border-style: solid;
  border-width: 3px;
  border-color: @color-very-good;
  background-color: rgb(255,255,255);
  border-radius: 20px;
  text-transform: uppercase;
  color: @color-very-good;
  padding-left: 20px;
  padding-right: 20px;
  outline:none;

  &:hover, &.selected {
    color: rgb(255,255,255);
    background-color: @color-very-good;
  }
}





