// main: ../app.less
html {
    position: relative;
    height: 100%;
}

body {
    margin: 0px;
    position: relative;
    height: 100%;
}

#page {
    position: relative;
    height: 100%;
    min-width: 500px;
}
#content {
    left: 0px;
    right: 0px;
    top: 50%;
}
#copyright {
    left: 0px;
    right: 0px;
    bottom: 13%;
}

        
:required:focus {
  border: 1px solid #f00; 
}

.required:after { 
    
}

body {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
}
.site-content {
    margin: 0px auto;
    width: 1000px;
    
    .text-muted {
        color: #ccc;
    }
    
    .breadcrumb {
        background:@color-darkest-gray;
        padding: 0px;
        text-align: left;
        font-size: 12px;
        text-transform: uppercase;
        color: @color-light-white;
        font-weight: 400;
        margin-bottom: 0px;
        list-style: none;
        border-radius: none 0px;

        a {
          color: #FFF;
          text-decoration: underline;
        }
    }
    
    .site-content-header {
        background:@color-light-gray;
        margin-bottom: 20px;
        padding:10px;
    }
    
    .site-content-header + .site-content-header {
    margin-bottom: 0px;
    }
    
    .site-content-header-success {
        background: @color-success;
        color: @color-light-white;
        margin: 0px;
        padding: 10px;
        
        h2 {
            font-size: 28px;
            margin:0px;
            text-align:left; 
            padding:0px;
            margin: 0px;
        }
        
        small {
            font-size: 16px;
            color: @color-light-white;
        }
    }

    .site-content-header-error {
        background: @color-error;
        color: @color-light-white;
        margin: 0px;
        padding: 10px;
        
        h2 {
            font-size: 28px;
            margin:0px;
            text-align:left; 
            padding:0px;
            margin: 0px;
        }
        
        small {
            font-size: 16px;
            color: @color-light-white;
        }
    }

  .site-content-header-warning {
    background: @color-warning;
    color: @color-light-white;
    margin: 0px;
    padding: 10px;

    h2 {
      font-size: 28px;
      margin:0px;
      text-align:left;
      padding:0px;
      margin: 0px;
    }

    small {
      font-size: 16px;
      color: @color-light-white;
    }
  }
    
    .site-content-page {
        
        .form-control-feedback {
            float:right;
            position:relative;
            right:-35px; top: -35px;
        }
        
        span.has-success {
            color: #13c355;
            font-size: 16px;
        }
        
        .has-success .form-control-feedback {
            color: #13c355;
            font-size: 21px;

        }
        
        .has-error .form-control-feedback {
            color: #fe0537;
            font-size: 21px;
        }
        
        h2{
            font-size: 28px;
            margin:0px;
            text-align:left; 
            padding:10px 0px 10px 0px;
        }
        
        h4 {
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 20px;
        }
        
        .two-cols {
            margin-bottom: 30px;
        }
        
        margin: 0px auto;
        width: 820px;
        text-align: left;
        
         button{
            width: 250px;
         }
        
        input[type='checkbox'] {
            width: 20px;
            height: 20px;
            margin-top: 2px;
            border-radius: none 0px;
        }

        div.submit {
            margin-top:35px;
            margin-bottom: 35px;
            display: inline-table;
        }

        .sub-action {
          width: 30px;
          border-radius: 0px;
        }

        .remove {
          padding-top: 7px; 
          padding-right: 7px;
          position: absolute;
          right: 0;
          top: 0;
          display: none;
        }

        .remove a {
          color: #000;
        }

        .featured {
          padding-top: 7px; 
          padding-left: 7px;
          position: absolute;
          left: 0;
          top: 0;
          display: none;
        }

        .featured a {
          color: #000;
        }
    }
}

.thin-content {
  margin: 0px auto;
  width: 828px;
}

.email-center {
  margin: 25px auto;
  width: 80%;
}

.thin-content-center {
  margin: 0px auto;
  width: 828px;
  text-align: center;
}

.section-title {
  color: #ccc;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  margin: 15px 0px 15px;
}

.section-title-center {
  color: #ccc;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 15px 0px 15px;
}

.btn.btn-default {
  background: #ddd;
  border: 0px none;
  height: 36px;
  line-height: 36px;
  padding: 0px 10px;
  border-radius: 36px;
  min-width: 36px;
  text-align: center;
}
.btn.btn-primary {
  background: @color-blue-1;
  border: 0px none;
  height: 40px;
  padding: 0px;
  line-height: 40px;
  padding: 0px 20px;
  border-radius: 20px;
  text-transform: uppercase;
  color: #fff;
}
.btn.btn-warning {
  background: @color-yellow;
  border: 0px none;
  height: 42px;
  line-height: 42px;
  font-weight: 700;
  color: #3f3f3f;
  padding: 0px 30px;
  border-radius: 21px;
  text-align: center;
}

[class*="badger"] {
    position: relative;
    margin: 15px 0;
    padding: 39px 19px 14px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 0px;
    
}

[class*="badger"]:after {
    content: attr(data-badger);
    position: absolute;
    top: 0;
    padding: 3px 7px;
    font-size: 13px;
    font-weight: bold;
    background: #999;
    color: #fff;
    font-weight: 100;
    cursor: pointer;
    
}

.media-frame[data-badger=""]:after, .media-frame:not([data-badger]):after {
    content: none;
}

.media[data-badger=""]:after, .media:not([data-badger]):after {
    content: none;
}

.badger-left:after {
    left: 0;
    border-radius: 6px 0 6px 0;
}

.badger-right:after {
    right: 0;
    border-radius: 0 0px 0 6px;
}

.badger-danger:after {
    background: #d9534f;
}

.badger-warning:after {
    background: #f0ad4e;
}

.badger-success:after {
    background: #215ab5;
}

.badger-info:after {
    background: #5bc0de;
}

.badger-inverse:after {
    background: #222;
}

.form-cols:first-of-type {
  padding-right: 0px !important;
}

.form-cols:last-of-type {
  padding-left: 5px !important;
}

.categories {
  width: 220px !important;
  display: inline;
}

.negotiate {
  padding-left: 30px;
}

.vertical-line-left {
  border-left: 1px solid #CCC;
  padding-left: 25px;
  margin-left: -1px;
  padding-right: 33px;
}

.vertical-line-right {
  border-right: 1px solid #CCC;
  padding-right: 0px;
  padding-left: 25px;
}

.withoutMargin {
  margin: 0;
}

.filters {
  padding-left: 5px;
  text-transform: uppercase;
}

.filters a {
  color: #000;
}

.filter {
  margin-top: 0;
  margin-bottom: 0;

  label {
    font-family: 'Roboto Condensed', sans-serif;
  }
}

.center-text {
  text-align: center;
}

.advertising-box {
  height: 230px;
  width: 150px;
  margin-left: 8px;

  a {
    color: #000;
    text-decoration: none;
    z-index: 1200;
  }
}

.advertising-box .media-frame {
  margin-top: 0;
}

.advertising-list {
  display: inline-flex;
}

.advertising-list-similar {
  margin-left: -5px;
  display: flex;
}

.one-item {
  display: inherit;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.42;
  border-radius: 15px;
}

.white, .white a {
  color: #fff;
}

.radio-form-padding {
  padding-top: 6px;
}

.search {
  margin: 0 !important;
  width: auto !important;
}

.banner {
  padding-top: 10px;
  padding-bottom: 10px;
}

.page-circle {
  border-radius: 50%;
  background-color: rgb(221, 221, 221) !important;
  position: absolute;
  width: 36px;
  height: 36px;
  text-align: center;
  padding-top: 8px;
  margin-left: 2px !important;
  margin-right: 2px !important;

  &:hover{
    background-color: rgb(252, 221, 1) !important;
  }

  &.selected{
    background-color: rgb(252, 221, 1) !important;
  }
}

.page-custom {
  margin: 0 auto;
  display: table;
}

div.advertising {
  padding: 0px !important;

  .site-content-page {
    margin: 0 5px 0px 0;
    width: 1000px
  }
  
  .price{
    background-color: rgb(252, 221, 1);
    //position: absolute;
    height: 73px;
    text-align: center;
    padding-top: 20px;

    span {
      font-size: 24px;
    }
  }

  .status{
    background-color: rgb(252, 248, 244);
    //position: absolute;
    height: 73px;
    text-align: center;
    padding-top: 22px;

    span {
      font-size: 18px;
    }
  }

  .font-11 {
    font-size: 11px;
  }
  
  .advertising-detail {
    text-transform: uppercase;
  }

  .shared-social {
    padding-left: 0; 
    list-style-type: none; 
    li {
      display: inline;
    }
  }

  .sub-actions{
    a {
      color: #777;
    }
  }

  .status-online {
    &:before {
      content: ' \25CF';  
      color: rgb(19, 195, 85);
    }
  }

  .btn-success {
    width: 100% !important;
    border-radius: 21px;
    -webkit-border-radius: 21px;
    -moz-border-radius: 21px;
  }

  .btn-primary {
    width: 100% !important;
    border-radius: 21px;
    -webkit-border-radius: 21px;
    -moz-border-radius: 21px;
  }

  .media-featured {
    width: 500px;
    height: 400px;

    img {
      max-width: 500px;
      max-height: 400px;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .ratio {
      display:block;
      width:100%;
      height:auto;
    }
          
    iframe {
      position:relative;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
  }

  .media-list {
    display: inline-flex;
    width: 500px;
  }

  .media-box {
    width: 96px;
    height: 80px;
    margin-left: 4px;

    .media {
    width: 96px;
    height: 80px;
    margin: 5px 0;

    img {
      width: 96px;
      height: 80px;
    }

    .ratio {
      display:block;
      width:100%;
      height:auto;
    }
          
    iframe {
      position:relative;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
  }
  }

  
}

.avatar {
  border-radius: 150px;
  -webkit-border-radius: 150px;
  -moz-border-radius: 150px;
}

.btn.dropdown-toggle {
  border-radius: 0 !important;
  width: 100% !important;
  padding-left: 10px;

  &.btn-default {
    background-color: #FFF;
    border: 1px solid #ccc;
  }
}

#categories-list {
  padding-left: 16px !important;
}

.close {
  width: auto !important;
}

div.form-question{
  padding-right: 0px;
}

div.form-submit {

  .btn-warning {
    width: 100% !important;
    padding: 0px 20px;
    margin-top: 15px;
  }

}

div.question_answer {
  .answer{
    padding-left: 30px;
  }
}

div.transaction-sidebar {
  .btn-warning {
    width: 100% !important;
  }
}

.no-padding {
  padding: 0;
}

div.media {
  padding: 0px;
  width: 150px;
  height: 150px;
  margin: 5px 0;

  .ratio {
    display:block;
    width:100%;
    height:auto;
  }

  iframe {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }

  .media-bottom {
    background-color: rgb(64, 63, 65);
    position: absolute;
    bottom: 0;
    width: 150px;
    height: 30px;
    opacity: 0.8;
    text-align: center;
    padding-top: 5px;
    color: #FFF;

    a {
      color: #FFF;
      text-decoration: none;
    }

  }

}

div.media:hover .remove {
  display: block;
}

div.media:hover .featured {
  display: block;
}

.tag {
  font-size: 14px;
  padding: .3em .4em .4em;
  margin: 0 .1em;

  a {
    color: #bbb;
    cursor: pointer;
    opacity: 0.6;
    margin: 0 0 0 .3em;

    &:hover {
      opacity: 1.0
    }

    .glyphicon-white {
      color: #fff;
      margin-bottom: 2px;
    }
  }

  .remove {
    vertical-align: bottom;
    top: 0;
  }
}

.video-popup {
  max-width: 650px;
  margin: 40px auto;
  position: relative;
}

.media-item {
  cursor: zoom-in;
}