// main: ../app.less
#header {
    
    .nav-tabs > li > a:hover {
        background: none;
        border: none 0px; 
    }
    
    .nav-tabs > li > a {
        background: none;
        border: none 0px; 
    }
    
    
    
    .nav-tabs {
  border-bottom: 1px solid #fff;
    }
    
    
    .dropdown-menu {
  font-size: 13px;
  text-align: right;
  border: 0px;
  border-top: 0px;
  margin-top: 0px;
  width: 205px;
  border-radius: 0px;
    }
    
    .dropdown-menu ul li {
        border-bottom: 1px solid #ccc;
    }
    
    .nav > li > a {padding:0px}
    
  #connect-bar {
   background: #fff;
    text-align: right;
    padding: 5px 0px;
    text-transform: uppercase;

    a {
      color: #626263;
      text-decoration: none;
      letter-spacing: 2px;
      margin-left: 20px;
    }

  }
    
  #header-bar {
    background: @color-light-gray;

    .right-side {
      padding-top: 20px;

      > * {
        float: right;
        margin-left: 10px;
      }
      form {
        position: relative;

        .glyphicon {
          position: absolute;
          color: #ddd;
          font-size: 18px;
          left: 15px;
          top: 12px;
        }

        input {
          background: #fff;
          border: 1px solid #ddd;
          border-radius: 21px;
          color: #a1a0a3;
          padding: 0px 60px 0px 45px;
          height: 42px;
          width: 400px;
        }
        .btn {
          position: absolute;
          right: 3px;
          top: 3px;
        }
      }
    }
  }
    
.dropdown-menu > li > a {
  display: block;
  padding: 8px 10px 5px 0px;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}
        
}