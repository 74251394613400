// main: ../app.less
#footer {
  background: @color-dark-gray;
  padding-top: 25px;

  > .thin-content { padding-bottom: 10px; }

  .menu {
    float: left; 
    font-size: 14px;
    color: #ccc;
    margin-right: 80px;

    h3 {
      text-transform: uppercase;
      color: #999;
      font-size: 14px;
      font-weight: 700;
      margin: 0px 0px 10px;
    }
    ul {
      list-style-type: none;
      margin: 0px;
      padding: 0px;

      li { margin: 0px 0px 10px; }
    }
    a {
      color: #ccc;
      text-decoration: none;
    }

  }
  .logo {
    float: right;
    margin: 0px;
  }

  #copyright {
    color: #999;
    border-top: 1px solid #4e4d4e;
    padding: 10px 0px;
    line-height: 20px;

    a {
      color: #999;
      text-decoration: none;
    }
  }
}