// main: ../app.less
#menu {
  background: @color-blue-1;

  .navbar {
    border: 0px none;
    background: transparent;
    margin: 0px;
    text-align: center;
    text-transform: uppercase;

    .navbar-nav {
      display: inline-block;
      text-align: left;
      float: none;

      > li > a {
        color: #fff;
        padding-top: 20px;
      }
    }

  }
  .navbar-default .navbar-nav > .open > a,
  .navbar-default .navbar-nav > .open > a:hover,
  .navbar-default .navbar-nav > .open > a:focus { background: transparent; }

  .dropdown-menu {
    border: 0px none;
    background: #0a47a7;

    > li > a,
    > li > a:hover {
      background: transparent;
      color: #fff;
    }
    
  }

  .dropdown-menu .sub-menu {
      left: 100%;
      position: absolute;
      top: 0;
      visibility: hidden;
      margin-top: -1px;
  }

  .dropdown-menu li:hover .sub-menu {
      visibility: visible;
  }

  .dropdown:hover .dropdown-menu {
      display: table;
  }

  .nav-tabs .dropdown-menu, .nav-pills .dropdown-menu, .navbar .dropdown-menu {
      margin-top: 0;
  }

  .sub-menu:before {
      border-bottom: 7px solid transparent;
      border-left: none;
      border-right: 7px solid rgba(0, 0, 0, 0.2);
      border-top: 7px solid transparent;
      left: -7px;
      top: 10px;
  }
  .sub-menu:after {
      border-top: 6px solid transparent;
      border-left: none;
      border-right: 6px solid #fff;
      border-bottom: 6px solid transparent;
      left: 10px;
      top: 11px;
      left: -6px;
  }

  .caret-right {
    color: #fff;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
    border-left: 4px solid;
    display: inline-block;
    height: 0;
    vertical-align: right;
    width: 0;
    margin-left: 15px;
    float: right;
    margin-top: 6px;
  }

}


