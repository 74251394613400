// main: ../app.less
.jumbotron {
  background: @color-dark-gray url(../img/content/banner.jpg) center top /cover no-repeat;
  height: 550px;
  color: #fff;
  text-align: center;
  padding-top: 390px;
  margin-bottom: 0px;
  text-transform: uppercase;

  h1, h2 {
    margin: 0px;
    text-transform: uppercase;
    font-weight: 700;
    text-shadow: 0px 0px 3px #333;
  }
  h2 { font-size: 16px; }
  h1 { font-size: 30px; }
}

.home-nav {
  background: @color-yellow;
  padding: 13px 0px;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  color: @color-darkest-gray;
  font-weight: 700;

  a {
    color: @color-darkest-gray;
    margin: 0px 3px;
    text-decoration: none;
  }
}

.page-nav {
  background: @color-darkest-gray;
  padding: 13px 0px;
  line-height: 20px;
  text-align: left;
  font-size: 14px;
  text-transform: uppercase;
  color: @color-light-white;
  font-weight: 700;

  a {
    color: @color-darkest-gray;
    margin: 0px 3px;
    text-decoration: none;
  }
}

#content {
  margin-bottom: 20px;
}

#slider-wrapper {
  position: relative;
  margin-bottom: 25px;

  > .btn {
    position: absolute;
    height: 62px;
    width: 62px;
    padding: 0px;
    line-height: 62px;
    border-radius: 31px;
    font-size: 32px;
    color: #fff;
    top: 45px;
    left: -85px;
  }
  > .btn.btn-next {
    left: auto;
    right: -85px;
  }
}

#slider-holder {
  overflow: hidden;

  .slider {
    white-space: nowrap;
    -webkit-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    -moz-transition: all 400ms linear;
    transition: all 400ms linear;

    article {
      display: inline-block;
      margin-right: 20px;
      width: 150px;
      white-space: normal;

      figure { margin-bottom: 10px; }
    }
  }
}

.two-cols {
  margin-bottom: 25px;

  .discs {
    article {
      width: 126px;
      float: left;
      margin-right: 45px;
      text-align: center;
      color: #3f3f40;
      line-height: 1.1;
      margin-top: 25px;

      .icon {
        border: 1px solid @color-yellow;
        height: 126px;
        border-radius: 63px;
        margin-bottom: 20px;
        line-height: 126px;
        text-align: center;
      }
    }
    article:last-child { margin-right: 0px; }
  }

  label { text-transform: uppercase; }
  input {
    border-radius: 0px;
    box-shadow: 0px none;
  }
  .submit {
    text-align: right;
  }
}
